.product_tab_main {
  margin-top: 82px;
  color: #ffffff;
}

.what_cognisant {
  color: var(--red, #e93249);
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
}

.custom_section {
  margin: 82px 32px 82px 32px;
}

.title {
  color: var(--white, #fff);
  text-align: center;
  font-size: 42px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
}

.description {
  color: #fff;
  font-size: 18px;
  line-height: 150%;
  margin-top: 40px;
}

.subtitle {
  color: #fff;
  font-size: 42px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 100%;
  max-width: 648px;
}

.subdescription {
  color: #fff;
  font-size: 18px;
  line-height: 150%;
  margin-top: 32px;
  margin-right: 114px;
  max-width: 988px;
}

.overview_subtitle {
  color: #fff;
  font-size: 42px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 100%;
  max-width: 988px;
  margin-right: 114px;
}

.banner_img {
  margin-left: -81px;
  margin-right: -81px;
  padding: 36px 0;
}

.mobile_banner_img {
  display: none;
}

.hexagon_banner_img {
  margin-left: -42px;
  margin-right: -42px;
  padding: 30px 0;
}

/* Tablet */
@media screen and (max-width: 1023px) {
  .product_tab_main {
    margin-top: 32px;
    color: #ffffff;
  }
  .what_cognisant {
    font-size: 24px;
  }
  .custom_section {
    margin: 32px auto 32px;
  }
  .title {
    font-size: 32px;
    line-height: 150%;
    margin-top: 0;
    margin-bottom: 0;
  }
  .description {
    font-size: 14px;
    margin-top: 32px;
  }
  .subtitle {
    font-size: 32px;
    max-width: 581px;
    margin-right: 0;
  }
  .subdescription {
    font-size: 14px;
    line-height: 150%;
    margin-top: 24px;
    margin-right: 0;
  }
  .overview_subtitle {
    font-size: 32px;
    max-width: 481px;
    margin-right: 0;
  }
  .banner_img {
    display: block;
    margin-left: -21px;
    margin-right: -21px;
    padding: 30px 0;
  }
}

/* Mobile */
@media screen and (max-width: 744px) {
  .product_tab_main {
    margin-top: 42px;
    color: #ffffff;
  }
  .what_cognisant {
    font-size: 18px;
  }
  .custom_section {
    margin: 32px auto 32px;
  }
  .title {
    font-size: 28px;
    line-height: 150%;
    margin-top: 8;
    margin-bottom: 0;
  }
  .description {
    font-size: 12px;
    margin-top: 24px;
  }
  .subtitle {
    font-size: 28px;
    max-width: 100%;
    margin-right: 0;
  }
  .subdescription {
    font-size: 12px;
    line-height: 150%;
    margin-top: 24px;
    margin-right: 0;
  }
  .overview_subtitle {
    font-size: 28px;
    max-width: 100%;
    margin-right: 0;
  }
  .banner_img {
    display: block;
  }
  .mobile_banner_img {
    display: block;
    margin-left: -20px;
    margin-right: -20px;
  }
}
